import bgTopGradientUrl from '@/assets/images/bg-top-gradient.svg'
import { Box, Flex, useMediaQuery } from '@chakra-ui/react'
import { Footer as SharedFooter } from '@vaultwin/ui'
import Navbar from '../Navbar'

const menuItems = []

const Page = ({ children, withGradient = true, ...props }) => {
  const [moreThan1920] = useMediaQuery('(min-width: 1921px)', { ssr: false })
  return (
    <Flex
      flexDirection={'column'}
      alignItems={'stretch'}
      position={'relative'}
      overflowX={'hidden'}
      w={'full'}
      h={'100%'}
      _before={
        withGradient
          ? {
              content: `''`,
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${bgTopGradientUrl})`,
              bgRepeat: 'no-repeat',
              bgSize: `${moreThan1920 ? 'cover' : 'contain'}`,
              zIndex: -1,
              bgPosition: 'center top',
            }
          : {}
      }
      {...props}
    >
      <Navbar menuItems={menuItems} />

      <Box flex={1}>{children}</Box>
      <SharedFooter />
    </Flex>
  )
}

export default Page

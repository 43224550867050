import {
  Faq,
  Features,
  GettingStarted,
  HowItWorks,
  Intro,
  KeyFeatures,
  Page,
} from '@/components'
import { MainContainer } from '@vaultwin/ui'

const Home = () => {
  return (
    <Page>
      <MainContainer py={24}>
        <Intro />
        <Features />
        <KeyFeatures />
        <HowItWorks />
        <GettingStarted />
      </MainContainer>
      <Faq />
    </Page>
  )
}

export default Home

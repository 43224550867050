import { APPS, getBaseUrl } from '@vaultwin/utils'
import openWindow from './openWindow'

const launchApp = () => {
  const AppUrl = getBaseUrl(APPS.APP)

  openWindow(AppUrl, true)
}

export default launchApp

import { defineStyleConfig } from '@chakra-ui/react'

const modalTheme = defineStyleConfig({
  baseStyle: {
    header: {
      pr: 'var(--chakra-space-12)',
    },
  },
})

export default modalTheme

import { Container } from '@chakra-ui/react'

const MainContainer = ({ children, ...props }) => {
  return (
    <Container maxW={'82.5em'} {...props}>
      {children}
    </Container>
  )
}

export default MainContainer

import { Image } from '@chakra-ui/react'
import VWLogoUrl from '../assets/images/vw-logo.svg'

const VWLogo = ({ ...props }) => {
  return (
    <Image
      w={{ base: 100, lg: 150 }}
      src={VWLogoUrl}
      alt='VaultWin Logo'
      {...props}
    />
  )
}

export default VWLogo

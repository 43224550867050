const APPS = {
  WEB: 'WEB',
  APP: 'APP',
}

const APPS_URLS = {
  [APPS.WEB]: {
    PROD: import.meta.env.VITE_WEB_URL,
    DEV: import.meta.env.VITE_WEB_URL_DEV,
  },
  [APPS.APP]: {
    PROD: import.meta.env.VITE_APP_URL,
    DEV: import.meta.env.VITE_APP_URL_DEV,
  },
}

export { APPS_URLS, APPS }

import { createIcon } from '@chakra-ui/react'

const LinkedinSocial = createIcon({
  displayName: 'Linkedin',
  viewBox: '0 0 20 21',
  path: (
    <>
      <g clipPath='url(#clip0_4552_430)'>
        <path
          d='M19.9951 20.096V20.0952H20.0001V12.7602C20.0001 9.17188 19.2276 6.40771 15.0326 6.40771C13.0159 6.40771 11.6626 7.51438 11.1101 8.56355H11.0517V6.74271H7.07422V20.0952H11.2159V13.4835C11.2159 11.7427 11.5459 10.0594 13.7017 10.0594C15.8259 10.0594 15.8576 12.046 15.8576 13.5952V20.096H19.9951Z'
          fill='currentColor'
        />
        <path
          d='M0.330078 6.74341H4.47675V20.0959H0.330078V6.74341Z'
          fill='currentColor'
        />
        <path
          d='M2.40167 0.0959473C1.07583 0.0959473 0 1.17178 0 2.49761C0 3.82345 1.07583 4.92178 2.40167 4.92178C3.7275 4.92178 4.80333 3.82345 4.80333 2.49761C4.8025 1.17178 3.72667 0.0959473 2.40167 0.0959473V0.0959473Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_4552_430'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0 0.0959473)'
          />
        </clipPath>
      </defs>
    </>
  ),
})

export default LinkedinSocial

import { createIcon } from '@chakra-ui/react'

export const ErinaceusIcon = createIcon({
  displayName: 'ErinaceusIcon',
  viewBox: '0 0 400 400',
  path: (
    <path
      fill="currentColor"
      d="M381.988 292.869C380.191 292.869 378.459 293.14 376.824 293.636L337.71 243.342L319.698 121.767L285.928 167.92L234.147 83.4898L215.008 152.156L144.088 71.1062L138.459 162.292L46.1531 116.139L88.932 214.074L0 211.82L67.5391 277.112L2.25391 319.891L85.5508 328.894H379.734V328.739C380.475 328.83 381.228 328.894 381.988 328.894C391.938 328.894 400 320.831 400 310.881C400 300.938 391.938 292.869 381.988 292.869ZM320.452 295.645C312.292 295.645 305.673 289.025 305.673 280.866C305.673 272.708 312.293 266.087 320.452 266.087C328.614 266.087 335.223 272.708 335.223 280.866C335.223 289.025 328.613 295.645 320.452 295.645Z"
    />
  ),
})

import ChakraProvider from '@/providers/ChakraProvider'
import RouterProvider from '@/providers/RouterProvider'
import { useTranslation } from 'react-i18next'

function App() {
  const { i18n } = useTranslation()

  document.body.dir = i18n.dir()

  return (
    <ChakraProvider>
      <RouterProvider />
    </ChakraProvider>
  )
}

export default App

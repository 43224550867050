import { Box, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react'
import { AnimatePresence, motion, wrap } from 'framer-motion'
import { useState } from 'react'
import { CarouselButton } from '.'

const variants = {
  enter: (direction) => {
    return {
      y: direction > 0 ? -50 : 50, // Enter from top if direction > 0, bottom if direction < 0
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      y: direction > 0 ? 50 : -50, // Exit to bottom if direction > 0, top if direction < 0
      opacity: 0,
    }
  },
}

const MotionBox = motion(Box)

const Carousel = ({ items, ...props }) => {
  const [[page, direction], setPage] = useState([0, 0])
  const index = wrap(0, items.length, page)

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection])
  }

  return (
    <Flex overflow='hidden' direction={'column'} {...props}>
      <Flex flex={1} position={'relative'}>
        <AnimatePresence initial={false} custom={direction}>
          <MotionBox
            key={page}
            custom={direction}
            variants={variants}
            initial='enter'
            animate='center'
            exit='exit'
            transition={{
              y: { type: 'tween', ease: 'easeOut' },
              opacity: { duration: 0.1 },
            }}
            paddingLeft={{ base: '12px', md: '24px' }}
            position={'absolute'}
            width={'100%'}
            borderLeft={'3px solid'}
            borderLeftColor={'#7534FF'}
          >
            <Heading
              fontWeight={600}
              fontSize={{ base: 'x-large', lg: 'xx-large' }}
              color={useColorModeValue('gray.900', 'gray.100')}
            >
              {items[index].title}
            </Heading>
            <Text
              fontWeight={300}
              fontSize={{ base: 'medium', sm: 'large', lg: 'x-large' }}
            >
              {items[index].text}
            </Text>
          </MotionBox>
        </AnimatePresence>
      </Flex>

      <Flex gap={'16px'} alignSelf={'flex-start'} justifyContent='center'>
        <CarouselButton direction='left' onClick={() => paginate(-1)} />
        <CarouselButton direction='right' onClick={() => paginate(1)} />
      </Flex>
    </Flex>
  )
}

export default Carousel

import howItWorks2Url from '@/assets/images/how-it-works-2.webp'
import howItWorks3Url from '@/assets/images/how-it-works-3.webp'
import howItWorksUrl from '@/assets/images/how-it-works.webp'
import {
  Box,
  Flex,
  Heading,
  Image,
  ListItem as NativeListItem,
  OrderedList as NativeOrderedList,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const OrderedList = ({ children }) => {
  return (
    <NativeOrderedList
      spacing={{ base: '8px', md: '16px' }}
      fontSize={{ base: '16px', md: '18px' }}
      fontWeight={'300'}
    >
      {children}
    </NativeOrderedList>
  )
}

const ListItem = ({ title, children, ...props }) => {
  return (
    <NativeListItem lineHeight={'28px'} {...props}>
      {title && (
        <Text as='span' fontWeight={400}>
          {title}
        </Text>
      )}{' '}
      {children}
    </NativeListItem>
  )
}

const getTabs = ({ t }) => {
  return [
    {
      title: t('about.Lottery Mechanism'),
      subTitle: t('about.VaultWin operates as a non-loss lottery.'),
      text: (
        <OrderedList>
          <ListItem title={t('about.Purchase a Ticket')}>
            {t('about.Buy a ticket to enter a prize pool.')}
          </ListItem>
          <ListItem title={t('about.Prize Accumulation')}>
            {t('about.The prize money is generated from the rewards')}
          </ListItem>
          <ListItem title={t('about.Winning Chance')}>
            {t(
              'about.Your chances of winning are based on the average size of your deposit over the prize period.',
            )}
          </ListItem>
          <ListItem title={t('about.Platform Fee')}>
            {t(
              'about.A 2% fee is deducted from the total prize pool to cover operational costs.',
            )}
          </ListItem>
          <ListItem title={t('about.Return of Entry Amount')}>
            {t(
              "about.If you don't win, you can manually withdraw your entry amount",
            )}
          </ListItem>
        </OrderedList>
      ),
      imageUrl: howItWorksUrl,
    },
    {
      title: t('about.Flexibility in Participation'),
      text: (
        <OrderedList>
          <ListItem title={t('about.Boosting Chances')}>
            {t(
              'about.Participants can add money to their existing tickets at any time',
            )}
          </ListItem>
          <ListItem title={t('about.Removing Funds')}>
            {t(
              'about.Participants can remove their entire deposit at any time',
            )}
          </ListItem>
          <ListItem title={t('about.Minimum Participation Requirement')}>
            {t(
              'about.To attend the lottery, participants must leave their money in the game for at least 24 hours.',
            )}
          </ListItem>
        </OrderedList>
      ),
      imageUrl: howItWorks2Url,
    },
    {
      title: t('about.Prize Generation'),
      text: (
        <Text
          fontSize={{ base: '16px', md: '18px' }}
          fontWeight={'300'}
          lineHeight={'28px'}
        >
          <Trans
            i18nKey={
              'about.Prizes in VaultWin are generated through the rewards'
            }
          >
            Prizes in VaultWin are generated through the rewards earned on the
            <Text
              as={Link}
              textDecoration={'underline'}
              to={'https://lolik.com'}
            >
              lolik.com
            </Text>
            and
            <Text
              as={Link}
              textDecoration={'underline'}
              to={'https://mutuari.com'}
            >
              mutuari.com
            </Text>
          </Trans>
        </Text>
      ),
      imageUrl: howItWorks3Url,
    },
  ]
}

const HowItWorks = () => {
  const { t } = useTranslation()
  const tabs = getTabs({ t })

  return (
    <Box pb={'64px'}>
      <Flex gap={{ base: 16, sm: 20, md: 24 }} direction={'column'}>
        {tabs.map((tab, index) => (
          <Flex
            key={tab.title}
            alignItems={{ base: 'center' }}
            direction={{
              base: 'column-reverse',
              lg: index === 1 ? 'row-reverse' : 'row',
            }}
            gap={{ base: 4, md: 10 }}
          >
            <Box flex={1}>
              <Image
                maxW={{ base: '100%', sm: '400px', lg: '100%' }}
                src={tab.imageUrl}
                alt={t('about.How It Works')}
              />
            </Box>

            <VStack
              alignItems={'flex-start'}
              spacing={{ base: '18px', md: '36px' }}
              flex={1}
            >
              <Heading fontSize={['32px', '36px', '40px']} fontWeight={600}>
                {tab.title}
              </Heading>

              {tab.subTitle && (
                <Text fontSize={{ base: '18px', md: '20px' }} fontWeight={500}>
                  {tab.subTitle}
                </Text>
              )}

              {tab.text}
            </VStack>
          </Flex>
        ))}
      </Flex>
    </Box>
  )
}

export default HowItWorks

import introPoolsWebpUrl from '@/assets/images/intro-pools.webp'
import launchApp from '@/utils/launchApp'
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'

const Intro = () => {
  const { t } = useTranslation()
  const hideIntroBg = useBreakpointValue(
    { base: true, sm: true, md: false },
    { ssr: false },
  )

  return (
    <Flex flexDirection={{ base: 'column', md: 'row' }} position={'relative'}>
      <Box
        pt={{ base: '60px', md: '100px', lg: '200px' }}
        pb={{ base: '60px', md: '200px' }}
        maxW={700}
        flex={{ base: 1, md: 0.7, lg: 0.5 }}
      >
        <Heading
          lineHeight={{ base: 'normal', md: '88px' }}
          color={useColorModeValue('gray.900', 'gray.100')}
          fontWeight={600}
          fontSize={{ base: '50px', md: '80px' }}
        >
          <Trans i18nKey={'homepage.Secure Your Chances to Win'}>
            Secure Your
            <Text color={'primary.500'} as='span'>
              Chances
            </Text>
            to Win
          </Trans>
        </Heading>

        <Text
          mt={{ base: '20px', md: '40px' }}
          fontSize={{ base: '20px', md: '24px' }}
          fontWeight={300}
          lineHeight={{ base: 'normal', md: '32px' }}
          color={useColorModeValue('gray.900', 'gray.100')}
        >
          {t('homepage.A non-loss lottery experience')}
        </Text>

        <Button
          mt={{ base: '20px', md: '40px' }}
          variant={'action'}
          onClick={launchApp}
        >
          {t('homepage.Launch App')}
        </Button>
      </Box>

      <Box
        hidden={hideIntroBg}
        position={'relative'}
        flex={{ base: 1, md: 0.3, lg: 0.5 }}
      >
        <Image
          maxW={900}
          position={'absolute'}
          src={introPoolsWebpUrl}
          alt={t('homepage.Lotteries')}
        />
      </Box>
    </Flex>
  )
}

export default Intro

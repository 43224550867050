import { theme as nativeTheme } from '@chakra-ui/react'
import buttonTheme from './button'
import colors from './colors'
import fonts from './fonts'
import inputTheme from './input'
import linkTheme from './link'
import modalTheme from './modal'
import styles from './styles'

const { breakpoints: nativeBreakpoints } = nativeTheme

const currentMode = localStorage.getItem('chakra-ui-color-mode')

const breakpoints = {
  ...nativeBreakpoints,
  xl: '1320px',
}

const theme = {
  config: {
    initialColorMode: currentMode || 'light',
    useSystemColorMode: true,
  },
  styles,
  fonts,
  colors,
  breakpoints,
  components: {
    Button: buttonTheme,
    Link: linkTheme,
    Input: inputTheme,
    Modal: modalTheme,
  },
}

export default theme

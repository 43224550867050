import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './en/translation.json'
import ar from './ar/translation.json'
import tr from './tr/translation.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      tr,
      ar,
    },
    fallbackLng: 'en',
    debug: import.meta.env.DEV,
  })

export default i18n

import VwLogoUrl from '@/assets/images/vw-logo.svg'
import launchApp from '@/utils/launchApp'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Stack,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  GetNotifiedButton,
  LanguageDropdown,
  MainContainer,
} from '@vaultwin/ui'
import { useTranslation } from 'react-i18next'
import { Link as routerLink, useLocation } from 'react-router-dom'

export default function Navbar({ menuItems = [], ...props }) {
  const { colorMode, toggleColorMode } = useColorMode()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  return (
    <Box
      position={'sticky'}
      filter='auto'
      left={0}
      top={0}
      py={'20px'}
      zIndex={10}
      _before={{
        content: `""`,
        display: 'block',
        position: 'absolute',
        pointerEvents: 'none',
        left: 0,
        top: 0,
        zIndex: 0,
        width: '100%',
        height: '100%',
        backgroundColor: useColorModeValue(
          'rgb(241 235 255 / 30%)',
          'rgb(0 0 0 / 30%)',
        ),
        backdropFilter: 'blur(6px)',
      }}
      {...props}
    >
      <MainContainer>
        <Flex
          position={'relative'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <HStack zIndex={2} gap={{ base: 4, sm: 8, md: '100px' }}>
            <Box as={routerLink} to={'/'}>
              <Image w={{ base: 100, lg: 150 }} src={VwLogoUrl} alt='VW Logo' />
            </Box>

            <Stack direction={'row'} spacing={'24px'}>
              {menuItems.map((item) =>
                item.secure ? null : (
                  <Link
                    as={routerLink}
                    fontSize={'14px'}
                    _hover={{
                      textDecoration: 'none',
                    }}
                    color={
                      pathname === item.path
                        ? useColorModeValue('primary.500', 'primary.400')
                        : useColorModeValue('gray.800', 'gray.100')
                    }
                    fontWeight={400}
                    key={item.label}
                    to={item.path}
                  >
                    {t(`menu.${item.label}`)}
                  </Link>
                ),
              )}
            </Stack>
          </HStack>

          <Flex alignItems={'center'} direction={'row'} gap={3}>
            <GetNotifiedButton />
            <Button onClick={launchApp} ml={'auto'} variant={'action'}>
              {t('homepage.Launch App')}
            </Button>
            <Button
              width={{ base: '24px', md: '48px' }}
              onClick={toggleColorMode}
              _light={{
                bgColor: 'whiteAlpha.300',
                _hover: { bgColor: 'whiteAlpha.400' },
              }}
            >
              {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            </Button>

            <LanguageDropdown />
          </Flex>
        </Flex>
      </MainContainer>
    </Box>
  )
}

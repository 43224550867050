import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// biome-ignore lint/style/useNodejsImportProtocol: <explanation>
import { Buffer } from 'buffer'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './i18n'

globalThis.Buffer = Buffer

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

import Home from '@/pages/Home'
import {
  RouterProvider as NativeRouterProvider,
  createBrowserRouter,
} from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
])

const RouterProvider = () => <NativeRouterProvider router={router} />

export default RouterProvider

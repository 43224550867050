import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react'
import { MainContainer } from '@vaultwin/ui'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

const getFaq = (t) => {
  return [
    {
      title: t('faq.What do I need to join the game?'),
      text: t('faq.VaultWin is a game based on Bahamut blockchain'),
    },
    {
      title: t('faq.Can I lose my money?'),
      text: t(
        "faq.No, participants in VaultWin's non-loss lottery do not risk losing their money",
      ),
    },
    {
      title: t('faq.Where does the prize money come from'),
      text: (
        <Trans i18nKey={'faq.Depending on the pool type'}>
          Depending on the pool type, the prizes are generated from the rewards
          earned on the
          <Link
            as={RouterLink}
            to={'https://lolik.com'}
            textDecoration={'underline'}
          >
            Lolik.com
          </Link>
          or
          <Link
            as={RouterLink}
            to={'https://mutuari.com'}
            textDecoration={'underline'}
          >
            Mutuari.com
          </Link>
          yield sources.
        </Trans>
      ),
    },
    {
      title: t('faq.Which Prize Pool should I choose'),
      text: t('faq.Each prize pool has a unique'),
    },
    {
      title: t('faq.How are winners chosen'),
      text: (
        <Trans i18nKey={'faq.Winners are selected through a random process'}>
          Winners are selected through a random process
          <Link
            as={RouterLink}
            to={'https://vrf.erinaceus.io'}
            textDecoration={'underline'}
          >
            Erinaceus VRF
          </Link>
        </Trans>
      ),
    },
    {
      title: t('faq.How are the chances of my win calculated?'),
      text: (
        <Trans
          i18nKey={
            'faq.Your chances of winning are determined by the average weight of your deposit'
          }
        >
          Your chances of winning are determined by
          <Text as={'span'} fontWeight={600}>
            the average weight of your deposit
          </Text>
          over
          <Text as={'span'} fontWeight={600}>
            the prize period
          </Text>
          .
        </Trans>
      ),
    },
    {
      title: t('faq.How can I boost my chances'),
      text: (
        <Trans
          i18nKey={
            'faq.The larger your average deposit, the higher your odds of winning'
          }
        >
          <Text as={'span'} fontWeight={600}>
            The larger your average deposit
          </Text>
          the higher your odds of winning, and vice versa. Additionally,
          depositing funds for
          <Text as={'span'} fontWeight={600}>
            a longer period
          </Text>
          .
        </Trans>
      ),
    },
    {
      title: t(
        'faq.How will withdrawing my funds affect my participation in the lottery?',
      ),
      text: (
        <Trans
          i18nKey='faq.There are no penalties for withdrawing your funds during the lottery period'
          components={{ 1: <br /> }}
        />
      ),
    },
  ]
}

const Faq = ({ ...props }) => {
  const { t } = useTranslation()

  const faq = getFaq(t)

  return (
    <Flex position={'relative'} bgColor={'primary.800'} py={125} {...props}>
      <MainContainer>
        <Flex maxW={875} m={'auto'} flexDirection={'column'} gap={'72px'}>
          <Box>
            <Heading
              textAlign={'center'}
              color={'white'}
              lineHeight={'60px'}
              fontWeight={600}
              fontSize={{ base: '48px', md: '56px' }}
            >
              {t('faq.Frequently asked questions')}
            </Heading>
          </Box>
          <Box>
            <Accordion allowToggle transition={'none'}>
              {faq.map(({ title, text }, index) => (
                <AccordionItem
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  key={index}
                  borderRadius={'20px'}
                  bgColor={'primary.700'}
                  border={'none'}
                  mb={'24px'}
                  transition={'none'}
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        p={{ base: '15px', sm: '20px' }}
                        fontSize={{ base: '18px', md: '20px' }}
                        color={'white'}
                        fontWeight={500}
                        borderRadius={'16px'}
                        _expanded={{
                          bgColor: 'white',
                          color: 'primary.900',
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                        gap={{ base: '10px', sm: '25px' }}
                      >
                        <Box as='span' flex='1' textAlign='left'>
                          {title}
                        </Box>
                        {isExpanded ? (
                          <MinusIcon color={'primary.900'} fontSize='16px' />
                        ) : (
                          <AddIcon color={'white'} fontSize='16px' />
                        )}
                      </AccordionButton>

                      <AccordionPanel
                        transition={'none'}
                        p={{ base: '0 15px 15px', sm: '0 20px 20px' }}
                        bgColor={isExpanded ? 'white' : 'primary.700'}
                        borderBottomLeftRadius={'12px'}
                        borderBottomRightRadius={'12px'}
                        fontSize={'16px'}
                        fontWeight={300}
                        lineHeight={'24px'}
                        color={'primary.900'}
                      >
                        {text}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        </Flex>
      </MainContainer>
    </Flex>
  )
}

export default Faq

import { APPS_URLS } from './constants'

/**
 * Get base url of specific App
 *
 * @param {string} app - App name
 * @returns {string} - Base url of app with pathname
 */
const getBaseUrl = (app, pathname = '') => {
  const isProd = import.meta.env.PROD
  const baseUrl = isProd ? APPS_URLS[app].PROD : APPS_URLS[app].DEV

  return `${baseUrl}${pathname}`
}

export default getBaseUrl

import { IconButton } from '@chakra-ui/react'
import { ArrowLeft, ArrowRight } from '@phosphor-icons/react'

const CarouselButton = ({ direction, onClick, ...props }) => {
  return (
    <IconButton
      borderRadius={'7px'}
      boxSize={'64px'}
      fontSize={'32px'}
      color={'primary.500'}
      bgColor={'primary.50'}
      _hover={{ bgColor: 'primary.500', color: 'white' }}
      icon={direction === 'left' ? <ArrowLeft /> : <ArrowRight />}
      aria-label={direction === 'left' ? 'Previous' : 'Next'}
      onClick={onClick}
      {...props}
    />
  )
}

export default CarouselButton

const colors = {
  primary: {
    50: '#F1EBFF',
    100: '#E3D6FF',
    200: '#C8AEFF',
    300: '#AC85FF',
    400: '#915DFF',
    500: '#7534FF',
    600: '#5E2ACC',
    700: '#461F99',
    800: '#2F1566',
    900: '#170A33',
  },
  secondary: {
    50: '#FFF9EE',
    100: '#FFF4DE',
    200: '#FFE9BD',
    300: '#FFDD9B',
    400: '#FFD27A',
    500: '#FFC759',
    600: '#CC9F47',
    700: '#997735',
    800: '#665024',
    900: '#332812',
  },
  gray: {
    50: '#F5F6F7',
    100: '#E6E8EC',
    200: '#CDD2D8',
    300: '#B4BBC5',
    400: '#9BA4B1',
    500: '#838E9E',
    600: '#6A778B',
    700: '#42526B',
    800: '#243752',
    900: '#061C3D',
  },
  success: {
    500: '#0F9918',
  },
  tertiary: {
    50: '#F0F5FF',
    100: '#CEE0FA',
    200: '#9DC1F5',
    300: '#6DA1EF',
    400: '#3C82EA',
    500: '#0B63E5',
    600: '#094FB7',
    700: '#073B89',
    800: '#04285C',
    900: '#02142E',
  },
  alcohol: {
    50: '#FEEDFA',
    100: '#FCDBF4',
    200: '#FAB6E9',
    300: '#F792DE',
    400: '#F56DD3',
    500: '#F249C8',
    600: '#C23AA0',
    700: '#912C78',
    800: '#611D50',
    900: '#300F28',
  },
}

export default colors

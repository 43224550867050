import { Box, Divider, HStack, Text, VStack, Link } from '@chakra-ui/react'
import { MainContainer, SocialIconButton } from '.'
import VWLogo from './VWLogo'
import { FacebookSocial, LinkedinSocial } from '../icons'
import { TelegramLogo } from '@phosphor-icons/react'

const Footer = ({ children, ...props }) => {
  return (
    <Box {...props}>
      <MainContainer pt={'64px'} pb={'32px'}>
        <VStack gap={{ base: 4, md: 8 }} alignItems={'stretch'}>
          <HStack justifyContent={'space-between'}>
            <VWLogo />

            {children}
          </HStack>
          <Divider />
          <HStack alignItems={'center'} justifyContent={'space-between'}>
            <HStack>
              <SocialIconButton
                to={'https://www.facebook.com/Vaultwin/'}
                icon={<FacebookSocial />}
              />
              <SocialIconButton
                to={
                  'https://www.linkedin.com/company/vaultwin/?viewAsMember=true'
                }
                icon={<LinkedinSocial />}
              />
              <SocialIconButton
                to={'https://t.me/vaultwin'}
                icon={<TelegramLogo weight='bold' />}
              />
            </HStack>
            <Text
              _light={{ color: 'primary.700' }}
              _dark={{ color: 'gray.100' }}
              fontSize={'16px'}
              fontWeight={300}
              lineHeight={'24px'}
            >
              © 2024 -{' '}
              <Link href={'https://erinaceus.io'} target='_blank'>
                Erinaceus.io
              </Link>
            </Text>
          </HStack>
        </VStack>
      </MainContainer>
    </Box>
  )
}

export default Footer

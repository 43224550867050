import { defineStyleConfig } from '@chakra-ui/react'

const inputTheme = defineStyleConfig({
  baseStyle: {
    bg: 'red',
    border: '1px solid',
    _hover: {
      borderColor: 'primary.200',
    },
    _focus: {
      borderColor: 'primary.200',
      boxShadow: '0 0 0 2px rgba(113, 93, 165, 0.5)',
    },
  },
})

export default inputTheme

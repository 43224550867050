import { Menu, MenuItem, MenuButton, MenuList, Button } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'
import { LANGUAGE_CODES, LANGUAGE_LABELS, LANGUAGES } from '@vaultwin/locales'

export default function LanguageDropdown({ children, ...props }) {
  const { i18n } = useTranslation()

  const handleLanguageChange = (lng) => {
    if (lng === i18n.language) return

    i18n.changeLanguage(lng)
  }

  const currentLanguage = LANGUAGE_LABELS[i18n.language || LANGUAGE_CODES.EN]

  return (
    <Menu w={100} autoSelect={false} {...props}>
      <MenuButton
        w={100}
        _light={{
          bgColor: 'whiteAlpha.300',
          _hover: { bgColor: 'whiteAlpha.400' },
        }}
        as={Button}
        rightIcon={<ChevronDownIcon />}
      >
        {currentLanguage}
      </MenuButton>
      <MenuList minW='0' w={'100px'}>
        {LANGUAGES.map(({ code, label }) => (
          <MenuItem key={code} onClick={() => handleLanguageChange(code)}>
            {label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export const LANGUAGE_CODES = {
  EN: 'en',
  EN_US: 'en-US',
  TR: 'tr',
  AR: 'ar',
}

export const LANGUAGE_LABELS = {
  [LANGUAGE_CODES.EN]: 'ENG',
  [LANGUAGE_CODES.EN_US]: 'ENG',
  [LANGUAGE_CODES.TR]: 'TR',
  [LANGUAGE_CODES.AR]: 'AR',
}

export const LANGUAGES = [
  { code: LANGUAGE_CODES.EN, label: LANGUAGE_LABELS[LANGUAGE_CODES.EN] },
  { code: LANGUAGE_CODES.TR, label: LANGUAGE_LABELS[LANGUAGE_CODES.TR] },
  { code: LANGUAGE_CODES.AR, label: LANGUAGE_LABELS[LANGUAGE_CODES.AR] },
]

import { defineStyleConfig } from '@chakra-ui/react'

const outlineCommonStyles = {
  bg: 'transparent',
  border: '1.5px solid',
  borderRadius: '7px',
  _hover: {
    bg: 'primary.50',
    borderColor: 'primary.600',
    color: 'primary.600',
    _disabled: {
      opacity: 1,
      bgColor: 'white',
      color: 'primary.100',
      borderColor: 'primary.100',
    },
  },
  _active: {
    bgColor: 'primary.50',
    borderColor: 'primary.600',
    color: 'primary.600',
  },
  _disabled: {
    opacity: 1,
    bgColor: 'white',
    color: 'primary.200',
    borderColor: 'primary.200',
  },
}

const buttonTheme = defineStyleConfig({
  baseStyle: {
    width: 'auto',
    minHeight: {
      base: '40px',
      md: '48px',
    },
    fontWeight: '600',

    // bg: 'primary.300',
    // color: 'white',
    //   _hover: {
    //     bgColor: 'primary.400',
    //     _disabled: {
    //       backgroundColor: 'primary.400',
    //     },
    //   },
  },
  variants: {
    outline: {
      borderColor: 'primary.500',
      color: 'primary.500',
      ...outlineCommonStyles,
    },
    outlineWhite: {
      borderColor: 'white',
      color: 'white',
      ...outlineCommonStyles,
    },
    action: {
      padding: '0 32px',
      bg: 'primary.500',
      color: 'white',
      fontWeight: '600',
      borderRadius: '7px',
      _hover: {
        bg: 'primary.600',
        _disabled: {
          bgColor: 'primary.400',
        },
      },
      _active: {
        bgColor: 'primary.700',
      },
      _disabled: {
        opacity: 0.4,
        bgColor: 'primary.400',
      },
    },
    soft: {
      bg: 'primary.50',
      color: 'primary.500',
      borderRadius: '7px',
      _active: {
        bgColor: 'primary.200',
        borderColor: 'primary.600',
        color: 'primary.600',
      },
      _disabled: {
        opacity: 1,
        color: 'primary.300',
        bgColor: 'primary.50',
      },
      _hover: {
        bgColor: 'primary.100',
        color: 'primary.600',
        _disabled: {
          opacity: 1,
          color: 'primary.200',
          bgColor: 'primary.0',
        },
      },
    },
    social: {
      borderRadius: '5px',
      bg: 'primary.500',
      color: 'white',
      fontWeight: '600',
      bgColor: 'primary.700',
    },
  },
})

export default buttonTheme

import vozniWebPUrl from '@/assets/images/vozni.webp'
import {
  Box,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  Text,
} from '@chakra-ui/react'
import { Medal, Money, Repeat, Ticket } from '@phosphor-icons/react'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

const features = [
  {
    title: 'homepage.Buy Ticket',
    subtitle: 'homepage.Get Your Ticket',
    text: 'homepage.Purchase your entry ticket',
    Icon: (
      <Icon
        as={Ticket}
        fontSize={'32px'}
        color='primary.500'
        weight='duotone'
      />
    ),
  },
  {
    title: 'homepage.Win Prize',
    subtitle: 'homepage.Win Big',
    text: 'homepage.Stand a chance to win exciting prizes',
    Icon: <Icon as={Medal} fontSize={'32px'} color='primary.500' />,
  },
  {
    title: 'homepage.Get Back Your Amount',
    subtitle: 'homepage.No Loss, No Worry',
    text: "homepage.If you don't win, your entry amount is always returned",
    Icon: (
      <Icon as={Money} fontSize={'32px'} weight='duotone' color='primary.500' />
    ),
  },
  {
    title: 'homepage.Try Again',
    subtitle: 'homepage.Try Again Next Time',
    text: "homepage.Didn't win this time? No problem!",
    Icon: <Icon as={Repeat} fontSize={'32px'} color='primary.500' />,
  },
]

const Features = () => {
  const { t } = useTranslation()

  return (
    <Box my={14}>
      <Flex
        alignItems={'center'}
        direction={{ base: 'column-reverse', md: 'row' }}
      >
        <Box flex={0.5}>
          <Image src={vozniWebPUrl} alt='Erinaceus' />
        </Box>
        <Box flex={0.5} mb={{ base: 2, sm: 'unset' }}>
          <Heading
            fontWeight={600}
            fontSize={{ base: '42px', md: '48px', lg: '56px' }}
          >
            {t('homepage.Save and Win')}
          </Heading>
          <Text
            mt={{ base: '12px', md: '24px' }}
            fontWeight={300}
            fontSize={{ base: '16px', md: '18px' }}
            as={Trans}
            i18nKey='homepage.For those who love to win but avoid risks'
          >
            For those who love to win but avoid risks, we offer VaultWin built
            on the
            <Text as={'span'} fontWeight={600}>
              Bahamut
            </Text>
            blockchain and powered by
            <Link
              textDecoration={'underline'}
              as={RouterLink}
              to={'https://erinaceus.io'}
              target='_blank'
            >
              Erinaceus
            </Link>
            . Boost your chances and enjoy exciting prizes.
          </Text>
        </Box>
      </Flex>

      <HStack
        flexDirection={{ base: 'column', sm: 'row' }}
        flexWrap={{ base: 'unset', sm: 'wrap' }}
        alignItems={'stretch'}
        spacing={{ base: '12px', md: '24px' }}
      >
        {features.map(({ title, subtitle, text, Icon }, index) => (
          <Flex
            flex={{ base: 'auto', sm: '150px' }}
            maxW={{ base: 'auto', md: 310 }}
            borderRadius={'16px'}
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={index}
            border={'1px solid #E6E8EC'}
            bgColor={'white'}
            p={{ base: '12px', md: '24px' }}
            direction={'column'}
          >
            <Flex
              justifySelf={'flex-start'}
              alignSelf={'flex-start'}
              borderRadius={'8px'}
              p={'16px'}
              bgColor={'tertiary.50'}
            >
              {Icon}
            </Flex>
            <Heading
              lineHeight={{ base: 'unset', md: '26px' }}
              color={'gray.900'}
              fontWeight={400}
              fontSize={{ base: '16px', md: '18px' }}
              mt={'20px'}
              mb={'12px'}
            >
              {t(title)}
            </Heading>
            <Box
              color={'gray.700'}
              fontWeight={300}
              fontSize={{ base: '14px' }}
            >
              <Text>{t(subtitle)}</Text>
              <Text>{t(text)}</Text>
            </Box>
          </Flex>
        ))}
      </HStack>
    </Box>
  )
}

export default Features

import { defineStyleConfig } from '@chakra-ui/react'

const linkTheme = defineStyleConfig({
  baseStyle: {
    textDecoration: 'none',
    _hover: {
      textDecoration: 'none',
    },
  },
})

export default linkTheme

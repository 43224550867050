import { Button, IconButton, Box, useBreakpointValue } from '@chakra-ui/react'
import { TelegramLogo } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const TelegramIconButton = ({ ...props }) => {
  return (
    <IconButton
      as={Box}
      variant={'action'}
      minH={{ md: '40x' }}
      icon={<TelegramLogo weight='regular' />}
      {...props}
    />
  )
}

export default function GetNotifiedButton({ children, ...props }) {
  const { t } = useTranslation()
  const showOnlyIcon = useBreakpointValue(
    { base: true, sm: true, md: true, lg: false },
    { ssr: false },
  )

  if (showOnlyIcon) {
    return (
      <TelegramIconButton
        minH={'auto'}
        width={{ base: '24px', md: '48px' }}
        as={Link}
        to={'https://t.me/vaultwin'}
        {...props}
      />
    )
  }

  return (
    <Button
      as={Link}
      to={'https://t.me/vaultwin'}
      leftIcon={<TelegramIconButton borderRadius={5} />}
      ps={'4px'}
      _light={{
        bgColor: 'whiteAlpha.300',
        _hover: { bgColor: 'whiteAlpha.400' },
      }}
      {...props}
    >
      {t('common.Get Notified')}
    </Button>
  )
}

import gettingStarted1Url from '@/assets/images/getting-started-1.webp'
import gettingStarted2Url from '@/assets/images/getting-started-2.webp'
import gettingStarted3Url from '@/assets/images/getting-started-3.webp'
import {
  Box,
  Flex,
  Image,
  ListItem,
  OrderedList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { CarouselButton, Title } from '../shared'

const getSlides = ({ t }) => {
  return [
    {
      title: t('about.Purchasing Tickets'),
      text: (
        <OrderedList
          fontSize={{ base: '18px', md: '20px' }}
          fontWeight={300}
          lineHeight={'28px'}
          spacing={{ base: '6px', md: '12px' }}
          pl={isMobileOnly ? 2 : 0}
        >
          <ListItem>
            <Text fontWeight={400} as='span'>
              {t('about.Buy Ticket')}
            </Text>{' '}
            {t(
              "about.Navigate to the 'Live Pools' section and select a prize pool.",
            )}
          </ListItem>
          <ListItem>
            <Text fontWeight={400} as='span'>
              {t('about.Enter Amount')}
            </Text>{' '}
            {t(
              'about.Enter the amount you wish to deposit and purchase your ticket.',
            )}
          </ListItem>
          <ListItem>
            <Text fontWeight={400} as='span'>
              {t('about.Confirmation')}
            </Text>{' '}
            {t(
              "about.You will receive a confirmation of your ticket purchase and can view it under 'My Pools'.",
            )}
          </ListItem>
        </OrderedList>
      ),
      image: gettingStarted1Url,
    },
    {
      title: t('about.Process of Claiming Prizes'),
      text: (
        <OrderedList
          fontSize={{ base: '18px', md: '20px' }}
          fontWeight={300}
          lineHeight={'28px'}
          spacing={{ base: '6px', md: '12px' }}
          pl={isMobileOnly ? 2 : 0}
        >
          <ListItem>
            <Text fontWeight={400} as='span'>
              {t('about.Check Results')}
            </Text>{' '}
            {t('about.Visit the VaultWin site to check if you have won.')}
          </ListItem>
          <ListItem>
            <Text fontWeight={400} as='span'>
              {t('about.Claim Prize')}
            </Text>{' '}
            {t(
              "about.Navigate to 'My Pools' and click on 'Claim Prize' for the relevant ticket.",
            )}
          </ListItem>
          <ListItem>
            <Text fontWeight={400} as='span'>
              {t('about.Payout')}
            </Text>{' '}
            {t('about.The prize will be credited to your connected wallet.')}
          </ListItem>
        </OrderedList>
      ),
      image: gettingStarted2Url,
    },
    {
      title: t('about.Entry Amount Return'),
      text: (
        <Text fontWeight={300} lineHeight={'28px'}>
          {t(
            'about.If you do not win, you can manually withdraw your entry amount',
          )}
        </Text>
      ),
      image: gettingStarted3Url,
    },
  ]
}

const SampleNextArrow = (props) => {
  const { className, onClick } = props
  return (
    <CarouselButton
      direction='right'
      className={className}
      _before={{ content: `''` }}
      top={'100%'}
      left={'50%'}
      mr={'-70px'}
      mt={'45px'}
      position={'absolute'}
      onClick={onClick}
      bgColor={'transparent'}
      _focus={{
        bgColor: 'primary.500',
        color: 'white',
        _hover: { bgColor: 'primary.500', color: 'white' },
      }}
    />
  )
}

const SamplePrevArrow = (props) => {
  const { className, onClick } = props
  return (
    <CarouselButton
      direction='left'
      className={className}
      _before={{ content: `''` }}
      top={'100%'}
      left={'50%'}
      ml={'-70px'}
      mt={'45px'}
      bgColor={'transparent'}
      _focus={{
        bgColor: 'primary.500',
        color: 'white',
        _hover: { bgColor: 'primary.500', color: 'white' },
      }}
      position={'absolute'}
      onClick={onClick}
    />
  )
}

const GettingStarted = () => {
  const { t } = useTranslation()
  const slides = getSlides({ t })

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <Flex w={'full'} direction={'column'}>
      <Box
        m={'auto'}
        mb={'72px'}
        maxW={650}
        textAlign={{ base: 'left', md: 'center' }}
      >
        <Title mb={'24px'}>{t('about.Getting Started')}</Title>
        <Text
          fontSize={{ base: '16px', md: '18px' }}
          fontWeight={300}
          lineHeight={'26px'}
        >
          {t(
            'about.It refers to the initial steps or actions one takes to begin a new project, task, or journey',
          )}
        </Text>
      </Box>

      <Box
        bgColor={useColorModeValue('gray.50', 'primary.700')}
        borderRadius={'32px'}
        p={{ base: '16px', md: '32px' }}
        as={Slider}
        sx={{
          '.slick-list': {
            transition: 'height 0.25s',
          },
        }}
        {...settings}
      >
        {slides.map((slide, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <Box key={index}>
            <Flex
              gap={{ base: 4, md: 8, xl: 24 }}
              alignItems={'center'}
              direction={{ base: 'column', md: 'row' }}
            >
              <Box flex={1} maxW={{ xl: '640px' }}>
                <Image
                  borderRadius={'16px'}
                  src={slide.image}
                  alt={slide.title}
                />
              </Box>

              <Flex flex={1} direction={'column'}>
                <Title
                  fontSize={{ base: '20px', md: '24px' }}
                  fontWeight={500}
                  lineHeight={'32px'}
                  mb={{ base: '16px', md: '24px' }}
                  color={useColorModeValue('gray.900', 'gray.100')}
                >
                  {slide.title}
                </Title>

                {slide.text}
              </Flex>
            </Flex>
          </Box>
        ))}
      </Box>
    </Flex>
  )
}

export default GettingStarted

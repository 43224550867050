import keyFeaturesUrl from '@/assets/images/key-features.webp'

import { Box, Flex, Image } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Carousel, Title } from '../shared'

const getFeatures = (t) => {
  return [
    {
      title: t('about.Non-Loss Lottery'),
      text: t(
        'about.Participate without the risk of losing your entry amount.',
      ),
    },
    {
      title: t('about.Secure and Fair'),
      text: t('about.Utilizes Erinaceus VRF'),
    },
    {
      title: t('about.Decentralized'),
      text: t('about.Open-source code for transparency and trust.'),
    },
  ]
}

const KeyFeatures = () => {
  const { t } = useTranslation()
  const features = getFeatures(t)

  return (
    <Flex
      py={{ base: 20, md: 24 }}
      alignItems={{ md: 'center' }}
      direction={{ base: 'column', md: 'row' }}
      gap={{ base: '20px', md: '40px', lg: '85px' }}
    >
      <Flex direction={'column'} flex={1}>
        <Title mb={{ base: '24px', lg: '48px' }}>
          {t('about.Key Features')}
        </Title>
        <Box>
          <Carousel h={{ base: 225, lg: 250 }} items={features} p={2} />
        </Box>
      </Flex>

      <Box flex={1}>
        <Image
          m={'auto'}
          maxW={{ base: '100%', sm: '400px', lg: '500px', xl: '600px' }}
          src={keyFeaturesUrl}
          alt={t('about.Features')}
        />
      </Box>
    </Flex>
  )
}

export default KeyFeatures
